<ng-container *ngIf="state !== 'success'">
	<h2 class="header-align-center fw-normal">Welcome!</h2>
	<h3 class="margin-top-s header-align-center">Sign Up</h3>

	<form [formGroup]="form" style="display: grid; grid-gap: var(--gu)">
		<mat-form-field class="margin-top">
			<input autofocus matInput type="email" placeholder="E-Mail" formControlName="email" />

			<mat-error *ngIf="getErrors('email')">{{ getErrors('email') }}</mat-error>
		</mat-form-field>

		<div style="min-height: 80px" *ngIf="state === 'error'">
			<zui-inline-message size="m" header-text="An error occured">
				<i>{{ lastBackendStatus }}</i>
				<zui-icon-ewi-error-triangle slot="icon" size="l"></zui-icon-ewi-error-triangle>
			</zui-inline-message>
		</div>

		<div style="display: flex; justify-content: center; text-align: center">
			Accounts may only be created by authorized users.
		</div>

		<div class="margin-top" style="display: flex; justify-content: center">
			<zui-checkbox (change)="tcAccepted = $any($event).target.value"
				>By checking the box, I certify that I am an authorized user and that I have reviewed and
				agreed to the
				<a href="https://www.zeiss.com/data-protection" target="_blank"
					>CZV Privacy Policy<zui-icon-external-link
						size="xs"
						style="vertical-align: super; display: inline-block; margin-left: 4px"
					></zui-icon-external-link
				></a>
			</zui-checkbox>
		</div>

		<div class="margin-top-s" style="display: flex; justify-content: end">
			<zui-button
				[disabled]="form.invalid || !tcAccepted || busy"
				(click)="createAccount()"
				emphasis="active-primary"
			>
				<span *ngIf="!busy"> Request account </span>
				<zui-progress-ring *ngIf="busy" emphasis="active" size="s" mode="activity">
				</zui-progress-ring>
			</zui-button>
		</div>
	</form>
</ng-container>

<ng-container *ngIf="state === 'success'">
	<div class="margin-top-l">
		<zui-inline-message size="m" header-text="Thank you for signing up">
			<i>{{ lastBackendStatus }}</i>
			<br />
			<br />
			Please check your inbox.
			<zui-icon-ewi-check slot="icon" size="l"></zui-icon-ewi-check>
		</zui-inline-message>
	</div>
</ng-container>
