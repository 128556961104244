import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Build } from 'src/environments/build';
import { Environment } from 'src/environments/environment';

@Component({
	selector: 'vis-signup',
	templateUrl: './signup.component.html',
	styleUrls: ['./signup.component.scss'],
})
export class SignupComponent {
	form!: FormGroup;
	tcAccepted = false;
	busy = false;
	state: 'signUp' | 'success' | 'error' = 'signUp';
	lastBackendStatus = '';

	constructor(private _formBuilder: FormBuilder, private _http: HttpClient) {
		if (Environment.AppMeta.Environment !== 'PRD') {
			console.debug(Build);
		}
		this.initForm();
	}

	initForm() {
		this.form = this._formBuilder.group({
			email: new FormControl({ value: '', disabled: false }, [
				Validators.required,
				Validators.email,
			]),
		});

		this.form.get('email')?.valueChanges.subscribe((x) => {
			if (this.state === 'error') {
				this.state = 'signUp';
				this.lastBackendStatus = '';
			}
		});
	}

	getErrors(formControlName: string) {
		const bla = this.form.get(formControlName)?.errors!;

		for (const key in bla) {
			switch (key) {
				case 'email':
					return 'Must be a valid email address';
				case 'required':
					return 'Required';
			}
		}
		return null;
	}

	createAccount() {
		this.busy = true;
		this._http
			.post(
				Environment.API.Path + '/account',
				{ Email: this.form.get('email')?.value },
				{
					params: Environment.API.Params,
					responseType: 'text',
				}
			)
			.subscribe(
				(x) => {
					this.lastBackendStatus = x;
					this.state = 'success';
					this.busy = false;
				},
				(err) => {
					this.lastBackendStatus = err.error;
					this.state = 'error';
					this.busy = false;
				}
			);
	}
}
