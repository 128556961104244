import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';

const routes: Route[] = [
	{
		path: '',
		loadChildren: () => import('src/app/signup/signup.module').then((m) => m.SignUpModule),
	},
	{
		path: 'reset',
		loadChildren: () => import('src/app/reset/reset.module').then((m) => m.ResetModule),
	},
	{
		path: '**',
		redirectTo: '',
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}
