// prettier-ignore
 // this file is automatically generated by git.version.ts script
 export const Build = {
   appVersion: `1.0.0`,
   branch: `MAIN`,
   lastCommit: `dca514b`,
   lastCommitUrl: `https://dev.azure.com/zathena/Digital-Lens-Portal/_git/Frontend.CreateAccount/commit/dca514b6c1437932e0db6f2c1e8570a9baedf0e9`,
   lastCommitHash: `dca514b6c1437932e0db6f2c1e8570a9baedf0e9`,
   lastCommitTime: `2023-06-05T08:12:04+00:00`,
   lastCommitMessage: `Merged PR 894: #544 #941 Remove old storage accounts

#544 #941 Remove old storage accounts

Related work items: #544, #941`,
   lastCommitAuthor: `Markus Meyer`
 };
 