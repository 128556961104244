import { Component } from '@angular/core';
import '@zeiss/zui';
import { registerTheme, registerTypefaces, setAppTheme, themeBase } from '@zeiss/zui-theme-base';
import { themeLight } from '@zeiss/zui-theme-light';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent {
	constructor() {
		this.initZui();
	}

	private initZui() {
		registerTheme(themeBase);
		registerTheme(themeLight);
		registerTypefaces('/fonts');
		setAppTheme('light');
	}
}
