import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSliderModule } from '@angular/material/slider';
import { SignUpRoutingModule } from './signup-routing.module';
import { SignupComponent } from './signup.component';

@NgModule({
	declarations: [SignupComponent],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		MatInputModule,
		MatSliderModule,
		HttpClientModule,
		SignUpRoutingModule,
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SignUpModule {}
