export const Environment = {
	AppMeta: {
		Environment: 'STAGE',
	},
	API: {
		Path: 'https://func-eop-ac-stage-westeu-002.azurewebsites.net/api',
		Headers: {
			'Authorization-ZeissId': '',
		},
		Params: {
			code: 'PqQJbYkf0CqtbZyCV_mwZ6mS1CwVotEv3n2pOhAqTwKTAzFuRFJXng==',
		},
	},
};
